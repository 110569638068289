import React from "react";
import smallMedium from "../../images/social/small_medium.png";
import smallTelegram from "../../images/social/small_telegram.png";
import smallTwiter from "../../images/social/small_twiter.png";
import Styles from "./MenuSocialLinks.module.scss";

const MenuSocialLinks = () => (
	<div className={Styles.container}>
		<h4 className={Styles.title}>Follow us:</h4>
		<div>
			<a href="https://t.me/tribalbooks" target="_blank">
				<img className={Styles.icon} src={smallTelegram} alt="telegram"/>
			</a>
			<a href="https://twitter.com/tribalbooks" target="_blank">
				<img className={Styles.icon} src={smallTwiter} alt="twiter"/>
			</a>
			<a href="https://tribalbooks.medium.com/" target="_blank">
				<img className={Styles.icon} src={smallMedium} alt="medium"/>
			</a>
		</div>
	</div>
);

export default MenuSocialLinks;